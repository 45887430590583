import { useEffect } from 'react';
import Web3 from 'web3';


import contract from './contracts/DynamicRebalancer.json';

const abi = contract.abi;

// doesn't expose a button but just connects to the wallet in the background
const ConnectWalletButton = ({
    contractAddress,
    address,
    setAddress,
    setRebalancer,
    setWeb3
}) => {
    const connectWallet = async () => {
        console.log('connectWallet'); // 2 times because of React.StrictMode
        const web3 = new Web3(Web3.givenProvider || 'http://127.0.0.1:7545');
        const rebalancer = new web3.eth.Contract(abi, contractAddress);
        const accounts = await web3.eth.requestAccounts();
        const account = accounts[0];
        setAddress(account);
        setRebalancer(rebalancer);
        setWeb3(web3);
    }

    useEffect(() => {
        async function listenMMAccount() {
            window.ethereum.on("accountsChanged", async function () {
                console.log("accountsChanged");
                connectWallet();
            });
        }
        listenMMAccount();
        connectWallet();
    }, []) // only once

    return '';
/*
    if (address) {
        return '';
    } else {
        return (
            <Container>
                <Row>
                    <Col>
                        <Button onClick={connectWallet} className="justify-content-center">
                            Connect Wallet
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }*/
}

export default ConnectWalletButton;