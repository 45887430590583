import React from 'react';
import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';

import AccountDataDynamic from './AccountDataDynamic';
import ConnectWalletButton from '../ConnectWalletButton';
import { log } from './utils.js';

// const contractAddress = "0xC5e816759806Fa5DE92DCec98d83A3C6148963fD"; // local
const contractAddress = "0x4179C3b689EFaB8600E693A181449FA482E448Fd"; // DMC Testnet

function DynamicUI() {
  const [web3, setWeb3] = useState();
  const [address, setAddress] = useState();
  const [rebalancer, setRebalancer] = useState();
  const [lastTimestamp, setLastTimestamp] = useState();
  const [state, setState] = useState({
    account: undefined,
    accountExists: false,
    dusd: 0,
    sellName: 'SPY',
    sellAmount: 0,
    assetList: [],
    assetMap: new Map()
  });
  const [config, setConfig] = useState();
  const [configChanged, setConfigChanged] = useState(false);

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState();
  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalDeposit, setShowModalDeposit] = useState(false);
  const [showModalWithdraw, setShowModalWithdraw] = useState(false);

  const handleChangeInt = async (event) => {
    setState({ ...state, [event.target.name]: parseInt(event.target.value) || 0 })
  }
  const handleChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const onContractError = (error, receipt) => {
    setModalErrorMessage(error.message);
    setShowModalError(true);
  }

  const handleCreate = async () => {
    log('handleCreate');

    const parameter = [];
    const method = rebalancer.methods.config(parameter);
    await method.call({ from: address }, function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setShowModalSuccess(true);
  }

  const handleUpdate = async () => {
    log('handleUpdate');

    const parameter = [];
    for (const item of config) {
      parameter.push({
        name: item.name,
        percentage: Math.round(item.percentage * 100)
      });
    }
    const method = rebalancer.methods.config(parameter);
    await method.call({ from: address }, function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setConfigChanged(false);
    setShowModalSuccess(true);
  }

  const handleDiscard = async () => {
    log('handleDiscard');

    setLastTimestamp(Date.now());
  }

  const handleDeposit = async () => {
    log('handleDeposit');
    setShowModalDeposit(false);

    const method = rebalancer.methods.deposit();
    await method.call({ from: address, value: state.value }, function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address, value: state.dusd * 100 })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setShowModalSuccess(true);
  }

  const handleWithdraw = async () => {
    log('handleWithdraw');
    setShowModalWithdraw(false);

    const method = rebalancer.methods.withdraw("" + state.dusd * 100);
    await method.call({ from: address }, async function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setShowModalSuccess(true);
  }

  const handleSell = async () => {
    log('handleSell');
    setShowModalSell(false);

    const method = rebalancer.methods.sell(state.sellName, state.sellAmount);
    await method.call({ from: address }, async function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setShowModalSuccess(true);
  }

  const handleRebalance = async () => {
    log('handleRebalance');

    const method = rebalancer.methods.rebalance();
    await method.call({ from: address }, async function (error, result) {
      if (error) {
        onContractError(error, null);
      }
    });
    await method.send({ from: address })
      .on('error', onContractError);
    setLastTimestamp(Date.now());
    setShowModalSuccess(true);
  }

  const handleTemplateJuliansBeta = async () => {
    log('handleTemplateJuliansBeta');

    setConfig(config => [
      {
        'name': 'SPY',
        'percentage': 35
      }, {
        'name': 'TLT',
        'percentage': 15
      }, {
        'name': 'GLD',
        'percentage': 10
      }, {
        'name': 'GSG',
        'percentage': 10
      }, {
        'name': 'BTC',
        'percentage': 7.5
      }, {
        'name': 'ETH',
        'percentage': 7.5
      }
    ]);
    setConfigChanged(true);
  }

  const handleTemplateEmpty = async () => {
    log('handleTemplateEmpty');

    setConfig(config => []);
    setConfigChanged(true);
  }

  return (
    <Container className="p-3">
      <Container className="p-5 mb-4 bg-light rounded-3">
        <Row>
          <Col>
            <h1>Portfolio Rebalancer Testnet Demo</h1>
          </Col>
          <Col md="auto">
            {!web3 &&
              <Alert variant='danger'>Wallet not connected.</Alert>
            }
            {web3 && !state.accountExists && 
              <Button variant="outline-primary" onClick={handleCreate}>Init Portfolio</Button>
            }
            {state.accountExists && configChanged && 
              <ButtonGroup className="me-2">
                <Button variant="outline-primary" onClick={handleUpdate}>Update</Button>
                <Button variant="outline-primary" onClick={handleDiscard}>Discard</Button>
              </ButtonGroup>
            }
            {state.accountExists && !configChanged &&
            <ButtonToolbar md="auto">
              <ButtonGroup className="me-2">
                <Button variant="outline-primary" onClick={handleRebalance}>Rebalance</Button>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <DropdownButton title="Templates" variant="outline-primary">
                  <Dropdown.Item onClick={handleTemplateJuliansBeta}>Julians Beta Portfolio</Dropdown.Item>
                  <Dropdown.Item onClick={handleTemplateEmpty}>Empty</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <Button variant="outline-primary" onClick={() => setShowModalSell(true)}>Sell</Button>
              </ButtonGroup>
              <ButtonGroup className="me-2">
                <Button variant="outline-primary" onClick={() => setShowModalDeposit(true)}>Deposit</Button>
                <Button variant="outline-primary" onClick={() => setShowModalWithdraw(true)}>Withdraw</Button>
              </ButtonGroup>
            </ButtonToolbar>
            }
          </Col>
        </Row>
        <ConnectWalletButton contractAddress={contractAddress} address={address} setAddress={setAddress} setRebalancer={setRebalancer} setWeb3={setWeb3} />
        <AccountDataDynamic web3={web3} rebalancer={rebalancer} address={address} lastTimestamp={lastTimestamp} state={state} setState={setState} config={config} setConfig={setConfig} setConfigChanged={setConfigChanged} />
      </Container>
      
      <Modal show={showModalSuccess} onHide={() => setShowModalSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Smart Contract Call was successful.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModalSuccess(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalError} onHide={() => setShowModalError(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Smart Contract Call was NOT successful.<br/>{modalErrorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModalError(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalSell} onHide={() => setShowModalSell(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sell asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column>Assetname:</Form.Label>
            <Col>
              <Form.Select name="sellName" value={state.sellName} onChange={handleChange}>
                {state.assetList.map((item, index) => {
                  return (
                    <option value={item.name} key={index}>{item.name}</option>
                  );
                })}
              </Form.Select>
            </Col>
            <Form.Label column>Amount:</Form.Label>
            <Col>
              <Form.Control type="text" name="sellAmount" value={state.sellAmount} onChange={handleChangeInt} />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalSell(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSell()}>
            Sell
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalDeposit} onHide={() => setShowModalDeposit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deposit DUSD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Amount:</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control type="text" name="dusd" value={state.dusd} onChange={handleChangeInt} />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalDeposit(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleDeposit()}>
            Deposit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalWithdraw} onHide={() => setShowModalWithdraw(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw DUSD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Amount:</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control type="text" name="dusd" value={state.dusd} onChange={handleChangeInt} />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalWithdraw(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleWithdraw()}>
            Withdraw
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Container className="p-5 mb-4 bg-light rounded-3">
        <h3>Manual</h3>
        <p>This is a prototype for a porfolio rebalancer for DeFiMetaChain. Currently dTokens are not accessible in the DeFiMetaChain EVM, therefore I am faking a Dex (with fixed prices) in this prototype.</p>
        <p>This smart contract uses DeFiMetaChain Testnet.</p>
        <p>The UI hopefully is self explanatory.</p>
        <p>Using the Deposit button you can fund your account by depositing DFI-Wei (smallest denomination of DFI). You will get fake DUSD for it.</p>
        <p>Then you can configure your portfolio and buy/rebuy assets according to the configuration using the Rebalance button.</p>
      </Container>
    </Container>
  );
}

export default DynamicUI; 
