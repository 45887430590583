import React from 'react';

import Container from 'react-bootstrap/Container';

function DbtcDashboard() {
  return (
    <Container className='p-3 w-75'>
        <iframe src="https://lookerstudio.google.com/embed/reporting/5cf3d923-4e29-4802-b31f-4764a6b8d6cd/page/uXEID" title="dbtc-dashboard" width="768px" height="1850px" allowFullScreen></iframe>
    </Container>
  );
}

export default DbtcDashboard; 
