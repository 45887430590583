import React from 'react';
import Container from 'react-bootstrap/Container';
import DynamicUI from './dynamic/DynamicUI';
import DbtcDashboard from './dbtc/DbtcDashboard';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function MyNavbar() {
    return (
        <Router>
            <Navbar bg="dark" variant="dark" expand="lg" >
                <Container fluid>
                    <Navbar.Brand>Defichain8</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/*<Nav.Link href="/">Demo Portfolio</Nav.Link>
                            <Nav.Link href="/beta">Beta Portfolio</Nav.Link>
                            <Nav.Link href="/dynamic">Dynamic Portfolio</Nav.Link>*/}

                            <Nav.Link href="/">dBTC Dashboard</Nav.Link>
                            <Nav.Link href="/rebalancer">Portfolio Rebalancer Testnet Demo</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                {/*<Route exact path="/" element={<DemoUI />}/>
                <Route path="/beta"element={<BetaUI />}/>
                <Route path="/dynamic"element={<DynamicUI />}/>*/}

                <Route exact path="/" element={<DbtcDashboard />}/>
                <Route path="/rebalancer"element={<DynamicUI />}/>
            </Routes>
        </Router>
    )
}

export default MyNavbar;
