import React from 'react';
import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';

const AccountDataDynamic = ({
    web3,
    rebalancer,
    address,
    lastTimestamp,
    state,
    setState,
    config,
    setConfig,
    setConfigChanged
}) => {

    const [cashPercentage, setCashPercentage] = useState();
    const [assetValue, setAssetValue] = useState();
    const [assetAmount, setAssetAmount] = useState();
    const [deprecatedAssets, setDeprecatedAssets] = useState();

    useEffect(() => {
        const loadAccount = async () => {
            if (!web3 || !web3.utils.isAddress(address)) {
                return;
            }
            
            const assetList = await rebalancer.methods.getPrices().call();
            const assetMap = new Map();
            for (let key in assetList) {
                let item = assetList[key];
                assetMap.set(item.name, parseInt(item.price));
            }
            setState(prevState => ({
                ...prevState,
                assetList: assetList,
                assetMap: assetMap
            }));

            const result = await rebalancer.methods.getAccount(address).call();

            if (result.owner===address) {
                setState(prevState => ({
                    ...prevState,
                    accountExists: true,
                    account: result
                }));

                let assetConfig = [];
                let cp = 10000;
                const configAssets = new Set();
                for (const asset of result.assetConfig) {
                    assetConfig.push({
                        name: asset.name,
                        percentage: asset.percentage / 100
                    });
                    cp -= asset.percentage;
                    configAssets.add(asset.name);
                }
                setConfig(assetConfig);
                setConfigChanged(false);
                setCashPercentage(cp / 100);

                let values = {};
                let amounts = {};
                let deprecatedAssets = [];
                for (const asset of result.assetBalance) {
                    values[asset.name] = assetMap.get(asset.name) * asset.amount / 100;
                    amounts[asset.name] = asset.amount;
                    if (!configAssets.has(asset.name) && asset.amount>0) {
                        deprecatedAssets.push({
                            name: asset.name,
                            amount: asset.amount
                        })
                    }
                }
                setAssetValue(values);
                setAssetAmount(amounts);
                setDeprecatedAssets(deprecatedAssets);
            }
        }
        loadAccount();
    }, [lastTimestamp, address, rebalancer, web3])

    const calcValue = (name, amount) => {
        if (state.assetMap.has(name)) {
            return state.assetMap.get(name) * amount / 100;
        } else {
            return -1;
        }
    }

    const handleAssetNameChange = async (event, eventIndex) => {
        let items = [...config];
        let item = {...items[eventIndex]};
        item.name = event.target.value;
        items[eventIndex] = item;
        setConfig(items);
        setConfigChanged(true);
    }
    const handlePercentageChange = async (event, eventIndex) => {
        const re = /(^100([.]0{0,2})?)$|(^\d{1,2}([.]\d{0,2})?)$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            let items = [...config];
            let item = {...items[eventIndex]};
            item.percentage = event.target.value;
            items[eventIndex] = item;
            setConfig(items);
            setConfigChanged(true);
        }
    }

    const handleAdd = async (event) => {
      event.preventDefault();

      setConfig(config => [...config, {
        'name': '',
        'percentage': 0
      }]);
      setConfigChanged(true);
    }
    const handleDelete = async (event, eventIndex) => {
      event.preventDefault();

      setConfig(config.filter((item, index) => index!==eventIndex));
      setConfigChanged(true);
    }

    if (state.accountExists) {
        return (
            <Container className="p-2">
                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Assetname</th>
                            <th>Percentage</th>
                            <th>Amount</th>
                            <th>Value</th>
                            <th><Button variant="primary" onClick={handleAdd}>Add</Button></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DUSD</td>
                            <td>{cashPercentage}%</td>
                            <td>{state.account.balanceDUSD / 100}</td>
                            <td>{state.account.balanceDUSD / 100}$</td>
                            <td></td>
                        </tr>
                        {config.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Form.Select name="assetName" value={item.name} onChange={event => handleAssetNameChange(event, index)}>
                                            <option></option>
                                            {state.assetList.map((item, index) => {
                                                return (
                                                    <option value={item.name} key={index}>{item.name} ({item.price / 100}$)</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <InputGroup>
                                            <Form.Control type="text" name="percentage" value={item.percentage} onChange={event => handlePercentageChange(event, index)} />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td>{assetAmount[item.name]}</td>
                                    <td>{assetValue[item.name]}{assetValue[item.name] && '$'}</td>
                                    <td><Button variant="primary" onClick={event => handleDelete(event, index)}>Delete</Button></td>
                                </tr>
                            );
                        })}
                        {deprecatedAssets.length>0 && 
                        <tr className='table-warning'>
                            <td colSpan={5}>The following assets are in your portfolio but not in your configuration. Click Rebalance to rebalance according to your configuration.</td>
                        </tr>
    }
                        {deprecatedAssets.map((item, index) => {
                            return (
                                <tr key={index} >
                                    <td>{item.name}</td>
                                    <td></td>
                                    <td>{item.amount}</td>
                                    <td>{calcValue(item.name,item.amount)}$</td>
                                    <td></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        );
    }
}

export default AccountDataDynamic;